import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../components/Layout';
import { Pagination } from '../components/Pagination';
import { ImagesTalePreviewData, TextTalePreviewData } from '../types';
import { TalesList } from '../components/TalesList';
import { getFieldsForTalePreview } from '../helpers/getFieldsForTalePreview';

interface CategoryPageTemplateData {
    categories: {
        _id: string;
        name: string;
    };
    allTextTales: {
        nodes: TextTalePreviewData[];
    };
    allImagesTales: {
        nodes: ImagesTalePreviewData[];
    }
}

export interface PageContextProps {
    currentPage: number;
    limit: number;
    numPages: number;
    skip: number;
    id: string;
    slug: string;
}

export const CategoryPageTemplate: FC<PageProps<CategoryPageTemplateData, PageContextProps>> = ({data, location, pageContext: {numPages, currentPage, slug}}) => {
    const { categories: {name}, allTextTales, allImagesTales } = data;

    const textTales = allTextTales.nodes.map(getFieldsForTalePreview('text'));
    const imagesTales = allImagesTales.nodes.map(getFieldsForTalePreview('images'));

    return (
        <Layout location={location} seoTitle={name}>
            <TalesList list={[...textTales, ...imagesTales]} title={name} />

            <Pagination currentPage={currentPage} pagesAmount={numPages} link={`/categories/${slug}`} />
        </Layout>
    );
}


export const query = graphql`
  query($id: Int!, $skip: Int!, $limit: Int!) {
      categories(_id: {eq: $id}) {
        _id
        name
        slug
      }
      allTextTales(limit: $limit, skip: $skip, sort: {order: ASC, fields: name}, filter: {categories: {elemMatch: {id: {eq: $id}}}}) {
        nodes {
          _id
          slug
          name
          description
          timeToRead
          author_id
        }
      }
      allImagesTales(limit: $limit, skip: $skip, sort: {order: ASC, fields: name}, filter: {categories: {elemMatch: {id: {eq: $id}}}}) {
        nodes {
          _id
          slug
          name
          description
          timeToRead
          author_id
        }
      }
  }
`
export default CategoryPageTemplate;
